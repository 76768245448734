import heic2any from 'heic2any'

async function getHeicToJpeg(file) {
  if (file.type !== 'image/heic' && file.type !== 'image/heif') {
    return file
  }
  const JpegBlob = await heic2any({
    blob: file,
    toType: 'image/jpeg',
  })

  const name = file.name.split('.')[0] + '.jpeg'
  const newFile = new File([JpegBlob], name)

  return newFile
}

async function convertToWebP(file) {
  const options = {
    maxWidth: 2048,
    maxHeight: 2048,
  }
  try {
    const result = await convertSingleFileToWebP(file, options)
    return result
  } catch (error) {
    console.error(`Error converting ${file.name}:`, error)
    showError(`이미지 변환 중 오류가 발생했습니다: ${file.name}`)
  }
}

async function convertSingleFileToWebP(file, options) {
  const newFile = await getHeicToJpeg(file)
  const dataUrl = await readFileAsDataURL(newFile)
  const img = await loadImage(dataUrl)
  const resizedImage = resizeImage(img, options.maxWidth, options.maxHeight)
  const blob = await createWebPBlob(resizedImage)
  const webpUrl = URL.createObjectURL(blob)
  return { webpUrl, fileName: file.name, file: blob }
}

function readFileAsDataURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => resolve(e.target.result)
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

function resizeImage(img, maxWidth, maxHeight) {
  const canvas = document.createElement('canvas')
  let width = img.width
  let height = img.height

  if (maxWidth && width > maxWidth) {
    height = (maxWidth / width) * height
    width = maxWidth
  }

  if (maxHeight && height > maxHeight) {
    width = (maxHeight / height) * width
    height = maxHeight
  }

  canvas.width = width
  canvas.height = height

  const ctx = canvas.getContext('2d')
  if (ctx) {
    ctx.drawImage(img, 0, 0, width, height)
  }

  return canvas
}

function loadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = reject
    img.src = src
  })
}

function createWebPBlob(canvas) {
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob)
      } else {
        reject(new Error('Failed to create blob'))
      }
    }, 'image/webp')
  })
}

function showError(message) {
  const errorElement = document.getElementById('error-message')
  if (errorElement) {
    errorElement.textContent = message
  } else {
    console.error('Error element not found:', message)
  }
}

function clearError() {
  const errorElement = document.getElementById('error-message')
  if (errorElement) {
    errorElement.textContent = ''
  }
}

function handleFileSelection(event) {
  const fileInput = event.target
  const fileNameDisplay = document.querySelector('.file-name')
  const submitButton = document.getElementById('submitButton')

  clearError()

  if (fileInput.files.length > 0) {
    const file = fileInput.files[0]
    if (fileNameDisplay) fileNameDisplay.value = file.name
    if (submitButton) submitButton.disabled = false
  } else {
    if (fileNameDisplay) fileNameDisplay.value = ''
    if (submitButton) submitButton.disabled = false
  }
}

function showSpinner() {
  const spinner = document.getElementById('spinner-overlay')
  if (spinner) {
    spinner.classList.remove('none-spin')
  }
}

function hideSpinner() {
  const spinner = document.getElementById('spinner-overlay')
  if (spinner) {
    spinner.classList.add('none-spin')
  }
}

function initializeTagInput() {
  const tagContainer = document.querySelector('.tag-container')
  const tagInput = document.getElementById('tagInput')
  let isComposing = false
  let lastChar = ''

  tagInput.addEventListener('input', function (e) {
    const currentValue = e.target.value
    const lastCharEntered = currentValue[currentValue.length - 1]

    if (!isComposing && lastCharEntered === ' ' && lastChar !== ' ') {
      e.preventDefault()
      addTag()
    }

    lastChar = lastCharEntered
  })

  tagInput.addEventListener('keydown', function (e) {
    if (!isComposing) {
      if (e.key === 'Enter') {
        e.preventDefault()
        addTag()
      } else if (e.key === 'Backspace' && tagInput.value === '') {
        e.preventDefault()
        removeLastTag()
      }
    }
  })

  tagInput.addEventListener('compositionstart', () => {
    isComposing = true
  })

  tagInput.addEventListener('compositionend', (e) => {
    isComposing = false
    if (e.data.endsWith(' ')) {
      addTag()
    }
  })

  function addTag() {
    if (isTwo()) {
      return
    }
    const value = tagInput.value.trim()
    if (value && !tagExists(value)) {
      const tag = document.createElement('span')
      tag.classList.add('tag')
      tag.textContent = value

      const closeBtn = document.createElement('span')
      closeBtn.classList.add('tag-close')
      closeBtn.textContent = '×'
      closeBtn.addEventListener('click', function () {
        tag.remove()
      })

      tag.appendChild(closeBtn)
      tagContainer.insertBefore(tag, tagInput)
      tagInput.value = ''
      lastChar = ''
    }
  }

  function isTwo() {
    const tags = tagContainer.querySelectorAll('.tag')
    return !(tags.length < 2)
  }

  function removeLastTag() {
    const tags = tagContainer.querySelectorAll('.tag')
    if (tags.length > 0) {
      tags[tags.length - 1].remove()
    }
  }

  function tagExists(value) {
    const existingTags = tagContainer.querySelectorAll('.tag')
    return Array.from(existingTags).some(
      (tag) => tag.textContent.slice(0, -1) === value,
    )
  }

  // 포커스가 태그 입력창을 벗어날 때 태그 추가
  tagInput.addEventListener('blur', addTag)
}

async function handleSubmit() {
  clearError()

  const formData = new FormData()

  // 각 필드의 값을 가져와서 FormData에 추가
  formData.append('shopName', document.getElementById('shopName').value)
  formData.append('name', document.getElementById('name').value)
  formData.append('phoneNumber', document.getElementById('phoneNumber').value)
  formData.append('address', document.getElementById('address').value)
  formData.append(
    'detailAddress',
    document.getElementById('detailAddress').value,
  )
  formData.append('clubName', document.getElementById('clubName').value)

  // 전문분야 태그 추가
  const tags = document.querySelectorAll('.tag')
  tags.forEach((tag, index) => {
    formData.append(`specialty[${index}]`, tag.textContent.slice(0, -1))
  })

  // Validation
  if (!formData.get('shopName')) {
    showError('샵 이름을 입력해주세요.')
    return
  }
  if (!formData.get('name')) {
    showError('대표님 성함을 입력해주세요.')
    return
  }
  if (!formData.get('phoneNumber')) {
    showError('샵 연락처를 입력해주세요.')
    return
  }
  if (!formData.get('address')) {
    showError('샵 주소를 입력해주세요.')
    return
  }
  if (tags.length === 0) {
    showError('전문분야를 하나 이상 입력해주세요.')
    return
  }

  // 파일 추가 및 변환
  const fileInput = document.getElementById('cover')
  if (fileInput.files.length > 0) {
    const originalFile = fileInput.files[0]
    const { file } = await convertToWebP(originalFile)
    formData.append('photo', file, convertToWebpExtension(originalFile.name))
  } else {
    showError('대표사진을 선택해주세요.')
    return
  }

  showSpinner()

  // AJAX를 사용하여 서버로 데이터 전송
  fetch('/sign-up', {
    method: 'POST',
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      hideSpinner()
      if (data.success) {
        window.location.href = '/sign-up/success'
      } else {
        showError('입점신청 중 오류가 발생했습니다: ' + data.message)
      }
    })
    .catch((error) => {
      hideSpinner()
      showError('입점신청 중 오류가 발생했습니다: ' + error.message)
    })
}

function initializeComponents() {
  const fileInput = document.getElementById('cover')
  const submitButton = document.getElementById('submitButton')

  if (fileInput) {
    fileInput.addEventListener('change', handleFileSelection)
  } else {
    console.error('File input element not found')
  }

  if (submitButton) {
    submitButton.addEventListener('click', handleSubmit)
  } else {
    console.error('Submit button not found')
  }

  initializeTagInput()
  initializeDaumPostcode()
  initializeFileInput()
  // Hide spinner when page loads (in case it's visible from a previous upload)
  hideSpinner()
}

// DOM이 로드된 후 초기화 함수 실행
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', initializeComponents)
} else {
  initializeComponents()
}

// 우편번호 찾기 찾기 화면을 넣을 element
var element_wrap = document.getElementById('wrap')

function initializeDaumPostcode() {
  const searchAddress = document.getElementById('searchAddress')
  searchAddress.addEventListener('click', sample3_execDaumPostcode)
  const inputAddress = document.getElementById('address')
  inputAddress.addEventListener('click', sample3_execDaumPostcode)
  const btnFoldWrap = document.getElementById('btnFoldWrap')
  btnFoldWrap.addEventListener('click', foldDaumPostcode)
}

function foldDaumPostcode() {
  // iframe을 넣은 element를 안보이게 한다.
  element_wrap.style.display = 'none'
}

function sample3_execDaumPostcode() {
  // 현재 scroll 위치를 저장해놓는다.
  var currentScroll = Math.max(
    document.body.scrollTop,
    document.documentElement.scrollTop,
  )
  new daum.Postcode({
    oncomplete: function (data) {
      // 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.
      // 각 주소의 노출 규칙에 따라 주소를 조합한다.
      // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
      var addr = '' // 주소 변수
      var extraAddr = '' // 참고항목 변수

      //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
      if (data.userSelectedType === 'R') {
        // 사용자가 도로명 주소를 선택했을 경우
        addr = data.roadAddress
      } else {
        // 사용자가 지번 주소를 선택했을 경우(J)
        addr = data.jibunAddress
      }

      // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
      if (data.userSelectedType === 'R') {
        // 법정동명이 있을 경우 추가한다. (법정리는 제외)
        // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
        if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
          extraAddr += data.bname
        }
        // 건물명이 있고, 공동주택일 경우 추가한다.
        if (data.buildingName !== '' && data.apartment === 'Y') {
          extraAddr +=
            extraAddr !== '' ? ', ' + data.buildingName : data.buildingName
        }
        // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
        if (extraAddr !== '') {
          extraAddr = ' (' + extraAddr + ')'
        }
        // 조합된 참고항목을 해당 필드에 넣는다.
        document.getElementById('address').value = extraAddr
      } else {
        document.getElementById('address').value = ''
      }

      // 우편번호와 주소 정보를 해당 필드에 넣는다.
      document.getElementById('address').value = addr
      // 커서를 상세주소 필드로 이동한다.
      document.getElementById('detailAddress').focus()

      // iframe을 넣은 element를 안보이게 한다.
      // (autoClose:false 기능을 이용한다면, 아래 코드를 제거해야 화면에서 사라지지 않는다.)
      element_wrap.style.display = 'none'

      // 우편번호 찾기 화면이 보이기 이전으로 scroll 위치를 되돌린다.
      document.body.scrollTop = currentScroll
    },
    // 우편번호 찾기 화면 크기가 조정되었을때 실행할 코드를 작성하는 부분. iframe을 넣은 element의 높이값을 조정한다.
    onresize: function (size) {
      element_wrap.style.height = size.height + 'px'
    },
    width: '100%',
    height: '100%',
  }).embed(element_wrap)

  // iframe을 넣은 element를 보이게 한다.
  element_wrap.style.display = 'block'
}

function initializeFileInput() {
  const fileNameInput = document.querySelector('.file-name')
  const fileInput = document.getElementById('cover')

  // .file-name을 클릭하면 파일 선택 대화상자가 열리도록 합니다.
  fileNameInput.addEventListener('click', function () {
    fileInput.click()
  })

  // 파일이 선택되면 .file-name의 값을 업데이트합니다.
  fileInput.addEventListener('change', function () {
    if (this.files && this.files.length > 0) {
      fileNameInput.value = this.files[0].name
    }
  })
}

function convertToWebpExtension(fileName) {
  // 파일 이름에서 마지막 점(.) 위치를 찾습니다.
  const lastDotIndex = fileName.lastIndexOf('.')

  // 확장자가 없거나 파일 이름이 점으로 시작하는 경우, 그냥 .webp를 추가합니다.
  if (lastDotIndex === -1 || lastDotIndex === 0) {
    return `${fileName}.webp`
  }

  // 파일 이름의 확장자 부분을 제외한1 나머지와 .webp를 결합합니다.
  const nameWithoutExtension = fileName.substring(0, lastDotIndex)
  return `${nameWithoutExtension}.webp`
}

export { initializeComponents }
